<template>
  <a-modal
    :visible="visible"
    :title="title"
    @ok="handleSubmit"
    @cancel="handleClose"
    width="500px"
    :confirmLoading="loading"
  >
    <a-form-model
      :model="form"
      ref="form"
      :rules="rules"
      layout="horizontal"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
    >
      <a-form-model-item label="角色名称" prop="name">
        <a-input v-model="form.name" placeholder="角色名称" />
      </a-form-model-item>
      <a-form-model-item label="角色级别" prop="level">
        <a-select :options="roleType" v-model="form.level" placeholder="请选择角色级别" allowClear></a-select>
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
import { roleAdd, roleEdit, roleDetail } from '@/api/employmentUser/role'
import appConfig from '../../../../config/app.config'

export default {
  name: 'RoleForm',
  data() {
    return {
      labelCol: { span: 5 },
      wrapperCol: { span: 17 },
      loading: false,
      edit: false,
      visible: false,
      form: {
        clientId: appConfig.clientId
      },
      rules: {
        name: [
          {
            required: true,
            message: '请输入角色名称',
            trigger: 'blur'
          }
        ],
        level: [
          {
            required: true,
            message: '请选择角色级别',
            trigger: 'change'
          }
        ]
      },
      roleType: []
    }
  },
  computed: {
    title() {
      return (this.edit ? '编辑' : '新增') + '系统角色'
    }
  },
  mounted() {
    const { level } = this.$store.getters.userInfo.roleInfo[0]
    this.roleType = this.handleGetDict('roleType').filter(item => item.value > level)
  },
  methods: {
    createModel(id) {
      this.edit = false
      this.$set(this, 'form', {
        clientId: appConfig.clientId
      })
      this.visible = true
    },
    editModel(id) {
      this.edit = true
      roleDetail(id).then(res => {
        this.$set(this, 'form', {
          ...res.data,
          level: res.data.level.toString()
        })
      })
      this.visible = true
    },
    handleSubmit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          const submit = this.edit ? roleEdit : roleAdd
          this.loading = true
          submit(this.form)
            .then(res => {
              if (res.success) {
                this.$emit('success')
                this.$message.success(`${this.title}成功`)
                this.handleClose()
              } else {
                this.$message.error(res.errMessage)
              }
            })
            .finally(() => {
              this.loading = false
            })
        }
      })
    },
    handleClose() {
      this.visible = false
      this.$refs.form.resetFields()
    }
  }
}
</script>

<style scoped>
</style>
